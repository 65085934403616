<template>
  <v-card :loading="processing">
    <v-toolbar color="titleBar" dark flat dense class="pr-10">
      <v-spacer />
      <v-menu bottom left>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                :disabled="productGroups.length == 0"
              >
                <v-icon v-if="productGroupsInclude.length > 0">
                  mdi-filter-check
                </v-icon>
                <v-icon v-else-if="productGroupsExclude.length > 0">
                  mdi-filter-remove
                </v-icon>
                <v-icon v-else> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("filter for product categories") }}</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <template v-for="(pg, i) in productGroups || []">
            <v-list-item
              v-if="pg.staffOnly == null || $store.getters.isStaff"
              :key="i"
              @click.stop="
                pg.filtered =
                  pg.filtered == null ? true : pg.filtered ? false : null;
                productGroups = [...productGroups];
              "
              dense
            >
              <v-list-item-icon>
                <v-icon
                  v-if="pg.filtered == null"
                  small
                  :color="pg.staffOnly ? 'staff' : ''"
                >
                  mdi-filter
                </v-icon>
                <v-icon
                  v-else-if="pg.filtered"
                  small
                  :color="pg.staffOnly ? 'staff' : ''"
                  >mdi-filter-check</v-icon
                >
                <v-icon v-else small :color="pg.staffOnly ? 'staff' : ''"
                  >mdi-filter-remove</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="pg.staffOnly ? 'staff--text' : ''">
                  {{ $t(pg.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <wiki slug="hardware-shop-overview" />
    </v-toolbar>

    <v-card-subtitle>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            class="mr-4 ml-4"
            append-outer-icon="mdi-magnify"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-subtitle>

    <v-card-text class="d-flex flex-wrap" v-if="items.length > 0">
      <v-card
        v-for="item in pagedItems"
        :key="item.id"
        class="ml-4 mb-4 d-flex flex-column"
        min-height="480"
        width="320"
        light
        @click="
          $router.push({
            name: 'hardware-details',
            params: { itemId: item.id },
          })
        "
      >
        <v-card-subtitle class="mt-n3 mb-n9 mr-n3">
          <v-row no-gutters>
            <v-col cols="12" class="text-right">
              <v-chip color="secondary" x-small>
                {{ $t(item.group_title || "other") }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-title class="card-title">
          <v-row>
            <v-col :cols="item.tags.includes('staff_only') ? 10 : 12">
              {{ item.name }}
            </v-col>
            <v-col cols="2" v-if="item.tags.includes('staff_only')">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="staff" large>
                    mdi-account-tie
                  </v-icon>
                </template>
                {{ $t("staff only") }}
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-img
          :src="item.image.replace('gfo-dev', 'gfo')"
          :alt="item.name"
          contain
        />
        <v-card-text>
          <div
            v-html="
              item.description.length > descriptionPreviewSize
                ? item.description.substring(0, descriptionPreviewSize) + '...'
                : item.description
            "
          ></div>
        </v-card-text>

        <v-divider />
        <v-card-actions class="mt-2" @click.stop>
          <hardware-shop-item-price :item="item" />
          <v-spacer />
          <v-btn
            icon
            color="primary"
            @click="minusCart(item)"
            v-if="item.id in cart"
            :disabled="minMinus(item)"
            class="mr-n3"
          >
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
          <v-badge
            :content="cart[item.id] || 0"
            :value="cart[item.id] || 0"
            color="primary"
            overlap
          >
            <v-btn
              icon
              color="primary"
              @click="plusCart(item)"
              :disabled="maxPlus(item)"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </v-badge>
        </v-card-actions>
      </v-card>
    </v-card-text>
    <v-card-text v-if="filteredItems.length > pageSize">
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="Math.ceil(filteredItems.length / pageSize)"
          total-visible="10"
          @input="changePage"
          circle
        />
      </div>
    </v-card-text>
    <v-card-text class="d-flex flex-wrap" v-if="!loading && items.length == 0">
      <v-alert type="info" v-if="reference == null">
        {{ $t("no items found") }}
      </v-alert>
      <v-alert type="info" v-else>
        {{ $t("nothingFoundRef") }}
        <br />
        <v-btn
          color="secondary"
          class="mt-4"
          :to="{
            name: 'hardware-order',
            hash: Math.random().toString(36).substr(2, 5),
            params: { reference: null },
          }"
          text
        >
          <v-icon class="mr-2">mdi-devices</v-icon>
          {{ $t("open hardware shop") }}
        </v-btn>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import HardwareShopItemPrice from "./HardwareShopItemPrice";
import { createNamespacedHelpers } from "vuex";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShopList",
  components: {
    HardwareShopItemPrice,
    Wiki,
  },
  mixins: [isMobile],
  props: {
    pageSize: {
      type: Number,
      default: 25,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    page: 1,
    search: "",
    descriptionPreviewSize: 400,
    productGroups: [],
    processingFiltering: false,
    processingPageing: false,
  }),
  watch: {
    items() {
      this.page = 1;
    },
    search() {
      this.page = 1;
    },
    loaded: {
      immediate: true,
      handler() {
        var productGroups = {
          staff: { id: 100000, title: "staff-only", staffOnly: true },
        };
        var id = 0;
        this.items.forEach(function (item) {
          var title = item.group_title || "other";
          if (!productGroups.hasOwnProperty(title)) {
            id = id + 1;
            productGroups[title] = {
              id: id,
              title: title,
            };
          }
        });
        this.productGroups = Object.values(productGroups);
      },
    },
  },
  computed: {
    ...mapState(["items", "cart", "reference", "loaded"]),
    ...mapGetters([
      "itemsInCart",
      "cartItems",
      "priceMonthly",
      "priceFix",
      "maxPlus",
      "minMinus",
    ]),
    processing() {
      return this.loading || this.processingPageing || this.processingFiltering;
    },
    filteredItems() {
      this.processingFiltering = true;
      this.search;
      this.productGroups;
      var that = this;
      var results = this.items.filter(function (item) {
        const staffOnly = item.tags.includes("staff_only");
        const matchGroupExcluded =
          that.productGroupsExclude.length > 0 &&
          that.productGroupsExclude.filter(function (pg) {
            return (
              pg == (item.group_title || "other") ||
              (pg == "staff-only" && staffOnly)
            );
          }).length > 0;
        const matchGroupIncluded =
          that.productGroupsInclude.length == 0 ||
          that.productGroupsInclude.filter(function (pg) {
            return (
              pg == (item.group_title || "other") ||
              (pg == "staff-only" && staffOnly)
            );
          }).length > 0;

        return (
          !matchGroupExcluded &&
          matchGroupIncluded &&
          (!that.search ||
            item.name.toLowerCase().indexOf(that.search.toLowerCase()) >= 0 ||
            item.description.toLowerCase().indexOf(that.search.toLowerCase()) >=
              0)
        );
      });

      this.processingFiltering = false;
      return results;
    },
    pagedItems() {
      this.processingPageing = true;
      var i = 0;
      var start = (this.page - 1) * this.pageSize + 1;
      var end = start + this.pageSize - 1;
      var results = this.filteredItems.filter(function (item) {
        i += 1;
        return i >= start && i <= end;
      });
      this.processingPageing = false;
      return results;
    },
    productGroupsInclude() {
      return this.productGroups
        .filter(function (pg) {
          if (pg.filtered == true) return pg;
        })
        .map(function (pg) {
          return pg.title;
        });
    },
    productGroupsExclude() {
      return this.productGroups
        .filter(function (pg) {
          if (pg.filtered == false) return pg;
        })
        .map(function (pg) {
          return pg.title;
        });
    },
  },
  methods: {
    ...mapMutations(["plusCart", "minusCart", "setCart", "removeCart"]),
    changePage() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.card-title {
  word-break: keep-all;
}
</style>

<i18n>
{
  "en": {
    "staff only": "staff only visible",
    "add to cart": "Add to cart",
    "no items found": "no items found",
    "open hardware shop": "Open hardware shop",
    "nothingFoundRef": "Unfortunately, we currently do not offer suitable, preconfigured devices for this order. If necessary, please order unconfigured hardware from our hardware shop.",
    "filter for product categories": "filter for product categories",
    "other": "Other",
    "TV": "TV",
    "Wifi": "Wifi",
    "Router": "Router",
    "Telefonie": "Telephony",
    "Kabel": "Cable",
    "staff-only": "Staff only"
  }, 
  "de": {
    "staff only": "nur für Mitarbeiter sichtbar",
    "add to cart": "in den Warenkorb legen",
    "no items found": "keine Artikel gefunden",
    "open hardware shop": "öffne Hardware Shop",
    "nothingFoundRef": "Derzeit bieten wir leider keine passenden, vorkonfigurierten Geräte für diese Bestellung an. Bestellen Sie bei Bedarf bitte unkonfigurierte Hardware aus unserem Hardware-Shop.",
    "filter for product categories": "nach Produktkategorien filtern",
    "other": "Sonstiges",
    "TV": "TV",
    "Wifi": "Wifi",
    "Router": "Router",
    "Telefonie": "Telefonie",
    "Kabel": "Kabel",
    "staff-only": "Nur Mitarbeiter"
  }
}
</i18n>
