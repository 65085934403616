var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.processing}},[_c('v-toolbar',{staticClass:"pr-10",attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.productGroups.length == 0}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[(_vm.productGroupsInclude.length > 0)?_c('v-icon',[_vm._v(" mdi-filter-check ")]):(_vm.productGroupsExclude.length > 0)?_c('v-icon',[_vm._v(" mdi-filter-remove ")]):_c('v-icon',[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("filter for product categories")))])])]}}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.productGroups || []),function(pg,i){return [(pg.staffOnly == null || _vm.$store.getters.isStaff)?_c('v-list-item',{key:i,attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();pg.filtered =
                pg.filtered == null ? true : pg.filtered ? false : null;
              _vm.productGroups = [..._vm.productGroups];}}},[_c('v-list-item-icon',[(pg.filtered == null)?_c('v-icon',{attrs:{"small":"","color":pg.staffOnly ? 'staff' : ''}},[_vm._v(" mdi-filter ")]):(pg.filtered)?_c('v-icon',{attrs:{"small":"","color":pg.staffOnly ? 'staff' : ''}},[_vm._v("mdi-filter-check")]):_c('v-icon',{attrs:{"small":"","color":pg.staffOnly ? 'staff' : ''}},[_vm._v("mdi-filter-remove")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:pg.staffOnly ? 'staff--text' : ''},[_vm._v(" "+_vm._s(_vm.$t(pg.title))+" ")])],1)],1):_vm._e()]})],2)],1),_c('wiki',{attrs:{"slug":"hardware-shop-overview"}})],1),_c('v-card-subtitle',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mr-4 ml-4",attrs:{"append-outer-icon":"mdi-magnify","clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),(_vm.items.length > 0)?_c('v-card-text',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.pagedItems),function(item){return _c('v-card',{key:item.id,staticClass:"ml-4 mb-4 d-flex flex-column",attrs:{"min-height":"480","width":"320","light":""},on:{"click":function($event){return _vm.$router.push({
          name: 'hardware-details',
          params: { itemId: item.id },
        })}}},[_c('v-card-subtitle',{staticClass:"mt-n3 mb-n9 mr-n3"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-chip',{attrs:{"color":"secondary","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t(item.group_title || "other"))+" ")])],1)],1)],1),_c('v-card-title',{staticClass:"card-title"},[_c('v-row',[_c('v-col',{attrs:{"cols":item.tags.includes('staff_only') ? 10 : 12}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.tags.includes('staff_only'))?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"staff","large":""}},on),[_vm._v(" mdi-account-tie ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("staff only"))+" ")])],1):_vm._e()],1)],1),_c('v-img',{attrs:{"src":item.image.replace('gfo-dev', 'gfo'),"alt":item.name,"contain":""}}),_c('v-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(
            item.description.length > _vm.descriptionPreviewSize
              ? item.description.substring(0, _vm.descriptionPreviewSize) + '...'
              : item.description
          )}})]),_c('v-divider'),_c('v-card-actions',{staticClass:"mt-2",on:{"click":function($event){$event.stopPropagation();}}},[_c('hardware-shop-item-price',{attrs:{"item":item}}),_c('v-spacer'),(item.id in _vm.cart)?_c('v-btn',{staticClass:"mr-n3",attrs:{"icon":"","color":"primary","disabled":_vm.minMinus(item)},on:{"click":function($event){return _vm.minusCart(item)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1):_vm._e(),_c('v-badge',{attrs:{"content":_vm.cart[item.id] || 0,"value":_vm.cart[item.id] || 0,"color":"primary","overlap":""}},[_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.maxPlus(item)},on:{"click":function($event){return _vm.plusCart(item)}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)],1)],1)}),1):_vm._e(),(_vm.filteredItems.length > _vm.pageSize)?_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.filteredItems.length / _vm.pageSize),"total-visible":"10","circle":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]):_vm._e(),(!_vm.loading && _vm.items.length == 0)?_c('v-card-text',{staticClass:"d-flex flex-wrap"},[(_vm.reference == null)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("no items found"))+" ")]):_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("nothingFoundRef"))+" "),_c('br'),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"secondary","to":{
          name: 'hardware-order',
          hash: Math.random().toString(36).substr(2, 5),
          params: { reference: null },
        },"text":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-devices")]),_vm._v(" "+_vm._s(_vm.$t("open hardware shop"))+" ")],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }