<template>
  <div>
    <hardware-shop-badge @openCart="$router.push({ name: 'hardware-cart' })" />
    <hardware-shop-list :loading="loading" :reference="reference" />
  </div>
</template>

<script>
import HardwareShopList from "@/components/orders/hardware/HardwareShopList";
import HardwareShopBadge from "@/components/orders/hardware/HardwareShopBadge";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShop",
  components: {
    HardwareShopList,
    HardwareShopBadge,
  },
  props: {
    reference: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(["loaded"]),
    ...mapGetters(["itemsInCart"]),
  },
  watch: {
    reference() {
      this.setReference(this.reference);
      this.setLoaded(false);
    },
    loaded: {
      immediate: true,
      handler() {
        this.getHardware();
      },
    },
    "$store.state.session": function () {
      this.reset();
    },
  },
  methods: {
    ...mapMutations(["setReference", "setLoaded"]),
    ...mapActions(["reset", "load"]),
    getHardware: function () {
      if (!this.loading && !this.loaded) {
        var that = this;
        this.loading = true;
        this.$http
          .get("shop/hardware", {
            params: {
              graduated_prices: true,
              reference: this.reference || null,
            },
          })
          .then((response) => {
            that.load(response.data);
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.v-application .mt-15 {
  margin-top: 60px !important;
}
</style>
